body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5em;
  font-feature-settings: "tnum" 0, "liga" 1, "ss01" 1 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body[dir="rtl"] {
  font-size: 16px;
}

@supports (font-variation-settings: normal) {
  body {
    font-family: "Inter var", sans-serif !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg {
  display: block;
}

.hidden-scrollbar {
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* IE 11 */
}
.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Opera, etc */
}

@media (min-width: 768px) {
  .ant-modal-wrap {
    margin: 1rem;
  }
}

*:focus {
  outline: 0 !important;
}